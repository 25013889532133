import React from "react";
import { StaticQuery, graphql } from "gatsby";
import TitleContentPage from "../components/title-content-page";

const TermsAndConditions = () => (
  <StaticQuery
    query={graphql`
      query termsAndConditionsQuery {
        allPrismicTermsAndConditions(limit: 1) {
          nodes {
            data {
              content {
                html
              }
              title {
                text
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const { content, title } =
        data.allPrismicTermsAndConditions.nodes[0].data;
      return <TitleContentPage title={title.text} content={content.html} />;
    }}
  />
);

export default TermsAndConditions;
